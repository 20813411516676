<!--
 * @Description: 
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-01-15 14:18:25
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar';
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '用户',
          list: [
            {
              name: '全部用户',
              path: '/admin/user/userList'
            },
            {
              name: '正常使用',
              path: '/admin/user/normal?index='+2
            },
            {
              name: '已禁用',
              path: '/admin/user/failed?index='+3
            }
          ]
        }
      ]
    };
  }
};
</script>
